import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { getBookingPreferencesForSelectedTime } from '../../../../utils/bookingPreferences/bookingPreferencesForSelectedTime';
import { updateCalendarErrors } from './calendarErrorsHandler';
import {
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { Service, ServiceType, SlotDetails } from '@wix/bookings-uou-types';
import {
  BOOKINGS_CALENDAR_REFERRAL_INFO,
  CalendarErrors,
  Preference,
  SetError,
} from '../../../../utils/bi/consts';
import { SetCalendarErrors } from '../setCalendarErrors/setCalendarErrors';
import { CALENDAR_PAGE_URL_PATH_PARAM } from '../../../../api/CalendarApi';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { SelectedBookingPreference } from '../../../../utils/bookingPreferences/bookingPreferences';
import { getSlotDuration } from '../../../../utils/duration/duration';
import { SetFocusedElement } from '../setFocusedElement/setFocusedElement';
import { FlowElements } from '../../Hooks/useFlow';

export const submitErrors = [
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_LOCATION,
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_DURATION,
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_STAFF_MEMBER,
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR,
];
export type OnSubmit = () => void;

export function createOnSubmitAction(
  {
    getControllerState,
    context: { t, businessInfo, settings, biLogger, wixSdkAdapter },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setCalendarErrorsAction: SetCalendarErrors,
  setFocusedElement: SetFocusedElement,
): OnSubmit {
  return async () => {
    const [state, setState] = getControllerState();
    const {
      selectedTime,
      bookableSlotsAtSelectedTime,
      selectedBookingPreferences,
      calendarErrors,
      selectedService,
    } = state;
    const isRescheduling = !!state.rescheduleBookingDetails;

    if (!selectedTime) {
      const calendarError =
        CalendarErrors.SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR;

      void biLogger.bookingsPaymentMethodSelectionNextClicked({
        userMessage: calendarError,
      });
      setCalendarErrorsAction(calendarError, SetError.ADD);
      setFocusedElement(FlowElements.DATE_TIME_NOTIFICATION);
    }
    const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
    if (bookableSlotsAtSelectedTime) {
      const bookingPreferences = getBookingPreferencesForSelectedTime({
        bookableSlotsAtSelectedTime,
        calendarErrors,
        t,
        settings,
        dateRegionalSettingsLocale,
        selectedBookingPreferences,
      });

      updateCalendarErrors(
        bookingPreferences,
        setCalendarErrorsAction,
        selectedBookingPreferences,
      );

      const isCalendarErrorsHasSubmitErrors = calendarErrors.some((error) =>
        submitErrors.includes(error),
      );

      const selectedSlot = getSelectedSlot({
        bookableSlotsAtSelectedTime,
        dateRegionalSettingsLocale,
        t,
        selectedBookingPreferences,
      });

      if (!isCalendarErrorsHasSubmitErrors && selectedSlot.length > 0) {
        if (isRescheduling) {
          setState({
            dialog: {
              type: DialogType.RescheduleConfirm,
              state: DialogState.IDLE,
            },
          });
        } else {
          const slug = await wixSdkAdapter.getServiceSlug(
            CALENDAR_PAGE_URL_PATH_PARAM,
          );
          const slot = selectedSlot[0].slot;
          const slotDetails: SlotDetails = getSlotDetails({
            slug,
            slot,
            selectedService,
          });

          void biLogger.bookingsContactInfoSaveSuccess({
            selectedSlot: selectedTime,
          });
          await wixSdkAdapter.navigateToBookingsContactInfoPage(
            slotDetails,
            BOOKINGS_CALENDAR_REFERRAL_INFO,
          );
        }
      } else {
        void biLogger.bookingsPaymentMethodSelectionNextClicked({
          selectedSlot: selectedTime,
        });
      }
    }
  };
}

const getSlotDetails = ({
  slug,
  slot,
  selectedService,
}: {
  slug: string;
  slot?: Slot;
  selectedService: Service;
}): SlotDetails => {
  const isClass = selectedService.info.type === ServiceType.GROUP;
  return {
    ...(isClass ? { id: slot?.id } : {}),
    slug,
    scheduleId: slot?.scheduleId!,
    start: new Date(slot?.start!).valueOf(),
    end: new Date(slot?.end!).valueOf(),
    staffMemberId: slot?.resource?.id!,
    locationId: slot?.location?.id ?? '',
  };
};

const getSelectedOptionValueByPreference = (
  preference: Preference,
  selectedBookingPreferences?: SelectedBookingPreference[],
): string | undefined => {
  const filteredSelectedPreference = selectedBookingPreferences?.filter(
    (selectedOption: SelectedBookingPreference) =>
      selectedOption.key === preference,
  );
  return filteredSelectedPreference?.[0]?.value;
};

export const getSelectedSlot = ({
  bookableSlotsAtSelectedTime,
  dateRegionalSettingsLocale,
  t,
  selectedBookingPreferences,
}: {
  bookableSlotsAtSelectedTime: SlotAvailability[];
  dateRegionalSettingsLocale: string;
  t: TFunction;
  selectedBookingPreferences?: SelectedBookingPreference[];
}): SlotAvailability[] => {
  return bookableSlotsAtSelectedTime.filter(
    (bookableSlot: SlotAvailability) => {
      const locationSelectedOption = getSelectedOptionValueByPreference(
        Preference.LOCATION,
        selectedBookingPreferences,
      );
      const staffMemberSelectedOption = getSelectedOptionValueByPreference(
        Preference.STAFF_MEMBER,
        selectedBookingPreferences,
      );

      const durationSelectedOption = getSelectedOptionValueByPreference(
        Preference.DURATION,
        selectedBookingPreferences,
      );

      const selectedLocation =
        !locationSelectedOption ||
        bookableSlot.slot?.location?.id === locationSelectedOption;

      const selectedStaffMember =
        !staffMemberSelectedOption ||
        bookableSlot.slot?.resource?.id === staffMemberSelectedOption;

      const selectedDuration =
        !durationSelectedOption ||
        getSlotDuration({
          rfcStartTime: bookableSlot.slot?.start!,
          rfcEndTime: bookableSlot.slot?.end!,
          t,
          dateRegionalSettingsLocale,
        }) === durationSelectedOption;
      return selectedLocation && selectedStaffMember && selectedDuration;
    },
  );
};
