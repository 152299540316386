import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { Optional } from '../../../../types/types';
import settingsParams from '../../settingsParams';
import { Plan } from '@wix/ambassador-checkout-server/types';
import { OfferedAsType, ServicePaymentDto } from '@wix/bookings-uou-types';
import { PaymentDtoMapper } from '@wix/bookings-uou-mappers';

export type PaymentOption = {
  id: string;
  value: string;
  isSelectable: boolean;
};

export enum PaymentSectionStatus {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export type PaymentSectionViewModel = {
  status: PaymentSectionStatus;
  paymentSectionTitle?: string;
  pricingPlanOnlyText?: string;
  invalidPricingPlanText?: string;
  loginHintText?: string;
  loginCTAText?: string;
  paymentOptions?: PaymentOption[];
  selectedPaymentOption?: string;
  userPlanDetails?: Plan;
};

export function createBookingPaymentDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<PaymentSectionViewModel> {
  if (state.paymentDetailsStatus === PaymentSectionStatus.LOADING) {
    return {
      status: PaymentSectionStatus.LOADING,
    };
  }
  const { settings, t, wixSdkAdapter, businessInfo } = context;
  const { offeredAs, paymentDetails } = state.selectedService.payment;
  const {
    selectedPaymentOption,
    userPricingPlan,
    paymentDetailsStatus,
  } = state;
  const regionalSettingsLocale = businessInfo.regionalSettingsLocale!;
  const isAnonymousUser = !wixSdkAdapter.getCurrentUser().loggedIn;

  const isOfferedAsPricingPlan = offeredAs.some(
    (offeredAsType) => offeredAsType === OfferedAsType.PRICING_PLAN,
  );
  const isOfferedAsPricingPlanAndOneTime = offeredAs.length > 1;
  const isOfferedAsPricingPlanOnly =
    isOfferedAsPricingPlan && !isOfferedAsPricingPlanAndOneTime;

  const userPlanDetails =
    selectedPaymentOption === OfferedAsType.PRICING_PLAN
      ? userPricingPlan?.defaultPlan
      : undefined;

  const showPricingPlanOnlyMessage =
    isOfferedAsPricingPlanOnly && !userPlanDetails;
  const paymentOptions = isOfferedAsPricingPlanAndOneTime
    ? getPaymentOptions({
        t,
        paymentDetails,
        regionalSettingsLocale,
      })
    : undefined;

  const isInvalidPlan =
    userPlanDetails?.validUntil &&
    new Date(userPlanDetails.validUntil) < new Date();

  const loginHintText = isAnonymousUser
    ? settings.get(settingsParams.pricingPlanLoginHint)
    : undefined;

  const loginCTAText = isAnonymousUser
    ? t('app.booking-details.payment.login.cta')
    : undefined;

  const invalidPricingPlanText = isInvalidPlan
    ? t('app.booking-details.payment.invalid-pricing-plan')
    : undefined;

  const pricingPlanOnlyText = showPricingPlanOnlyMessage
    ? settings.get(settingsParams.pricingPlanOnlyMessageText)
    : undefined;

  const paymentSectionTitle = isOfferedAsPricingPlan
    ? settings.get(settingsParams.paymentSectionTitle)
    : undefined;

  return {
    status: paymentDetailsStatus,
    loginHintText,
    loginCTAText,
    invalidPricingPlanText,
    pricingPlanOnlyText,
    paymentSectionTitle,
    userPlanDetails,
    paymentOptions,
    selectedPaymentOption,
  };
}

function getPaymentOptions({
  t,
  paymentDetails,
  regionalSettingsLocale,
}: {
  t: TFunction;
  paymentDetails: ServicePaymentDto;
  regionalSettingsLocale: string;
}): PaymentOption[] {
  const priceText = new PaymentDtoMapper(regionalSettingsLocale).priceText(
    paymentDetails,
  );
  return [
    {
      id: OfferedAsType.ONE_TIME,
      value: priceText,
      isSelectable: true,
    },
    {
      id: OfferedAsType.PRICING_PLAN,
      value: t('app.booking-details.payment.option.pricing-plan'),
      isSelectable: true,
    },
  ];
}
