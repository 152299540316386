import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  OfferedAsType,
  ServicePayment,
  ServicePaymentDto,
} from '@wix/bookings-uou-types';
import {
  PaymentDtoMapper,
  isServiceOfferedAsPricingPlan,
} from '@wix/bookings-uou-mappers';
import { formatRfcTimeStringToDateAndTimeView } from '../../../../utils/dateAndTime/dateAndTime';
import { getBookingPreferencesForSelectedTime } from '../../../../utils/bookingPreferences/bookingPreferencesForSelectedTime';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import settingsParams from '../../settingsParams';
import { BookingPreference } from '../../../../utils/bookingPreferences/bookingPreferences';

export type BookingDetailsPreferences = {
  bookingPreferences: BookingPreference[];
  titleText: string;
  clearText: string;
};

export type BookingDetailsViewModel = {
  serviceName: string;
  dateAndTime?: string;
  paymentDescription?: string;
  videoConferenceBadgeText?: string;
  preferences: BookingDetailsPreferences;
  ctaText: string;
};

export async function createBookingDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Promise<BookingDetailsViewModel> {
  const { businessInfo, t, settings, wixSdkAdapter } = context;
  const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
  const {
    selectedService,
    bookableSlotsAtSelectedTime,
    selectedTime,
    selectedBookingPreferences,
    calendarErrors,
  } = state;

  const serviceName = selectedService.info.name;

  const videoConferenceBadgeText = selectedService.videoConferenceProviderId
    ? settings.get(settingsParams.videoConferenceBadgeText)
    : '';

  const paymentDescription = await getPaymentDescription({
    wixSdkAdapter,
    payment: selectedService.payment,
    dateRegionalSettingsLocale,
    bookingDetailsPricingPlanText: settings.get(
      settingsParams.bookingDetailsPricingPlanText,
    ),
  });

  const dateAndTime = selectedTime
    ? formatRfcTimeStringToDateAndTimeView(
        selectedTime,
        dateRegionalSettingsLocale,
      )
    : '';
  const bookingPreferences = getBookingPreferencesForSelectedTime({
    bookableSlotsAtSelectedTime: bookableSlotsAtSelectedTime ?? [],
    calendarErrors,
    t,
    settings,
    dateRegionalSettingsLocale,
    selectedBookingPreferences,
  });

  const ctaSettingKey = getCtaSettingKeyAccordingToBookingFlow(state);
  const ctaText = settings.get(ctaSettingKey);

  return {
    serviceName,
    paymentDescription,
    dateAndTime,
    videoConferenceBadgeText,
    preferences: {
      bookingPreferences,
      clearText: settings.get(settingsParams.bookingDetailsClearText),
      titleText: settings.get(
        settingsParams.bookingDetailsPreferencesTitleText,
      ),
    },
    ctaText,
  };
}

const getCtaSettingKeyAccordingToBookingFlow = (state: CalendarState) => {
  const isRescheduling = !!state.rescheduleBookingDetails;
  const isPendingApprovalFlow =
    state.selectedService?.policy?.isPendingApprovalFlow;

  if (isRescheduling) {
    return settingsParams.bookingDetailsRescheduleText;
  } else if (isPendingApprovalFlow) {
    return settingsParams.bookingDetailsPendingApprovalText;
  }
  return settingsParams.bookingDetailsBookNowText;
};

const getPaymentDescription = async ({
  wixSdkAdapter,
  payment,
  dateRegionalSettingsLocale,
  bookingDetailsPricingPlanText,
}: {
  wixSdkAdapter: WixOOISDKAdapter;
  payment: ServicePayment;
  dateRegionalSettingsLocale: string;
  bookingDetailsPricingPlanText: string;
}) => {
  const paymentDescription = [];

  const isServiceBookableWithPricingPlan = await wixSdkAdapter.isPricingPlanInstalled();
  if (isOfferedAsOneTime(payment)) {
    const priceText = getPriceText(payment, dateRegionalSettingsLocale);
    paymentDescription.push(priceText);
  }
  if (
    isServiceOfferedAsPricingPlan(payment, isServiceBookableWithPricingPlan)
  ) {
    paymentDescription.push(bookingDetailsPricingPlanText);
  }
  return paymentDescription.join(' | ');
};

const isOfferedAsOneTime = (payment: ServicePayment) => {
  return payment.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0;
};

const getPriceText = (
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
) => {
  const paymentDto: ServicePaymentDto = payment.paymentDetails;
  const paymentDtoMapper = new PaymentDtoMapper(dateRegionalSettingsLocale);
  return paymentDtoMapper.priceText(paymentDto);
};
