import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { TFunction } from '../../components/BookingCalendar/controller';
import { CalendarErrors } from '../bi/consts';
import { CalendarContext } from '../context/contextFactory';
import {
  getBookingPreferences,
  BookingPreference,
  BookingPreferenceOption,
  SelectedBookingPreference,
} from './bookingPreferences';

export const getBookingPreferencesForSelectedTime = ({
  bookableSlotsAtSelectedTime,
  selectedBookingPreferences,
  calendarErrors,
  t,
  settings,
  dateRegionalSettingsLocale,
}: {
  bookableSlotsAtSelectedTime: SlotAvailability[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  t: TFunction;
  dateRegionalSettingsLocale: string;
  settings: CalendarContext['settings'];
}): BookingPreference[] => {
  const bookingPreferences = getBookingPreferences({
    dateRegionalSettingsLocale,
    settings,
    t,
  });
  return createBookingPreferencesForSelectedTime({
    bookingPreferences,
    bookableSlotsAtSelectedTime,
    selectedBookingPreferences,
    calendarErrors,
    t,
  });
};

const createBookingPreferencesForSelectedTime = ({
  bookingPreferences,
  bookableSlotsAtSelectedTime,
  selectedBookingPreferences,
  calendarErrors,
  t,
}: {
  bookingPreferences: BookingPreference[];
  bookableSlotsAtSelectedTime: SlotAvailability[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  t: TFunction;
}): BookingPreference[] => {
  return bookingPreferences.map((someBookingPreference: BookingPreference) => {
    const { key, error, placeholder } = someBookingPreference;
    const hasError = calendarErrors.includes(error.key);
    const options = createOptionsForBookingPreference({
      bookableSlotsAtSelectedTime,
      bookingPreferences,
      selectedBookingPreferences,
      someBookingPreference,
      t,
    });

    return {
      key,
      options,
      error: {
        key: error.key,
        message: hasError ? error.message : '',
      },
      placeholder,
    };
  });
};

const createOptionsForBookingPreference = ({
  bookableSlotsAtSelectedTime,
  bookingPreferences,
  selectedBookingPreferences,
  someBookingPreference,
  t,
}: {
  bookableSlotsAtSelectedTime: SlotAvailability[];
  bookingPreferences: BookingPreference[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  someBookingPreference: BookingPreference;
  t: TFunction;
}): BookingPreferenceOption[] => {
  const filteredBookableSlots: SlotAvailability[] = filterBookableSlotsAccordingToSelectedPreferences(
    {
      bookableSlotsAtSelectedTime,
      bookingPreferences,
      selectedBookingPreferences,
      someBookingPreference,
    },
  );

  let bookingPreferenceOptions: BookingPreferenceOption[] = [];
  bookableSlotsAtSelectedTime.forEach((bookableSlots) => {
    const slotBookingPreferenceOption = someBookingPreference.getBookingPreferenceOptionFromSlot!(
      bookableSlots,
    );
    const isSelectable = isBookingPreferenceOptionSelectable({
      filteredBookableSlots,
      someBookingPreference,
      slotBookingPreferenceOption,
    });

    bookingPreferenceOptions.push({
      ...slotBookingPreferenceOption,
      isSelectable,
    });
  });

  bookingPreferenceOptions = removeDuplicatePreferenceOptions(
    bookingPreferenceOptions,
  );

  return getBookingPreferencesOptionsWithEnrichedValue(
    bookingPreferenceOptions,
    t,
  );
};

const getBookingPreferencesOptionsWithEnrichedValue = (
  bookingPreferenceOptions: BookingPreferenceOption[],
  t: TFunction,
) => {
  const waitlistOnly = t('app.booking-details.dropdowns.waitlist-only');
  return bookingPreferenceOptions.map(
    (bookingPreferenceOption: BookingPreferenceOption) => {
      const valueWithWaitlistOnlyIndication = t(
        'app.booking-details.dropdowns.option-with-waitlist-only',
        {
          option: bookingPreferenceOption.value,
          waitlistOnly,
        },
      );
      return {
        ...bookingPreferenceOption,
        value: bookingPreferenceOption.isWithWaitingList
          ? valueWithWaitlistOnlyIndication
          : bookingPreferenceOption.value,
      };
    },
  );
};

const removeDuplicatePreferenceOptions = (
  bookingPreferenceOptions: BookingPreferenceOption[],
): BookingPreferenceOption[] => {
  let uniqueBookingPreferenceOptions: BookingPreferenceOption[] = [];

  bookingPreferenceOptions.forEach((bookingPreferenceOption) => {
    const uniqueBookingPreferenceOptionWithTheSameId = uniqueBookingPreferenceOptions.find(
      (uniqueBookingPreferenceOption: BookingPreferenceOption) =>
        bookingPreferenceOption.id === uniqueBookingPreferenceOption.id,
    );

    const isBookingPreferenceOptionNotExists = !uniqueBookingPreferenceOptionWithTheSameId;

    if (isBookingPreferenceOptionNotExists) {
      uniqueBookingPreferenceOptions.push(bookingPreferenceOption);
    } else {
      const shouldReplaceExistingOptionBecauseNewOptionIsWithoutWaitingList =
        !bookingPreferenceOption.isWithWaitingList &&
        uniqueBookingPreferenceOptionWithTheSameId?.isWithWaitingList;
      if (shouldReplaceExistingOptionBecauseNewOptionIsWithoutWaitingList) {
        uniqueBookingPreferenceOptions = uniqueBookingPreferenceOptions.filter(
          (bookingPreferenceOption: BookingPreferenceOption) =>
            bookingPreferenceOption.id !==
            uniqueBookingPreferenceOptionWithTheSameId?.id,
        );
        uniqueBookingPreferenceOptions.push(bookingPreferenceOption);
      }
    }
  });

  return uniqueBookingPreferenceOptions;
};

const filterBookableSlotsAccordingToSelectedPreferences = ({
  bookableSlotsAtSelectedTime,
  bookingPreferences,
  selectedBookingPreferences,
  someBookingPreference,
}: {
  bookableSlotsAtSelectedTime: SlotAvailability[];
  bookingPreferences: BookingPreference[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  someBookingPreference: BookingPreference;
}): SlotAvailability[] => {
  let filteredBookableSlotsAtSelectedTime = bookableSlotsAtSelectedTime;
  bookingPreferences.forEach((preference: BookingPreference) => {
    const selectedBookingPreference = selectedBookingPreferences?.find(
      (selectedPreference) => preference.key === selectedPreference.key,
    );

    if (
      selectedBookingPreference &&
      preference.key !== someBookingPreference.key
    ) {
      filteredBookableSlotsAtSelectedTime = filteredBookableSlotsAtSelectedTime.filter(
        (bookableSlot) =>
          preference.getBookingPreferenceOptionFromSlot!(bookableSlot).id ===
          selectedBookingPreference.value,
      );
    }
  });
  return filteredBookableSlotsAtSelectedTime;
};

const isBookingPreferenceOptionSelectable = ({
  filteredBookableSlots,
  someBookingPreference,
  slotBookingPreferenceOption,
}: {
  filteredBookableSlots: SlotAvailability[];
  someBookingPreference: BookingPreference;
  slotBookingPreferenceOption: BookingPreferenceOption;
}) => {
  return filteredBookableSlots.some((filteredSlot: SlotAvailability) => {
    return (
      someBookingPreference.getBookingPreferenceOptionFromSlot!(filteredSlot)
        .id === slotBookingPreferenceOption.id
    );
  });
};
