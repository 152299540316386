import {
  LocationType,
  QueryAvailabilityResponse,
  SlotAvailability,
  SlotResource,
} from '@wix/ambassador-availability-calendar/types';
import { ServiceLocationType } from '@wix/bookings-uou-types';
import { getLocalDateTimeStartOfDay } from '../../../src/utils/dateAndTime/dateAndTime';

const firstLocationAtBusinessLocation = {
  locationType: ServiceLocationType.OWNER_BUSINESS,
  formattedAddress: 'Shlomo Ibn Gabirol Street 114',
  id: 'firstLocationId',
  name: 'Tel Aviv',
};
const secondLocationAtBusinessLocation = {
  locationType: ServiceLocationType.OWNER_BUSINESS,
  formattedAddress: 'Derech Ruppin 11',
  id: 'secondLocationId',
  name: 'Jerusalem',
};
const thirdLocationAtBusinessLocationWithoutFormattedAddress = {
  locationType: ServiceLocationType.OWNER_BUSINESS,
  formattedAddress: '',
  id: 'thirdLocationId',
  name: 'Beer Sheva',
};
const firstLocationAtCustomLocation = {
  locationType: LocationType.OWNER_CUSTOM,
  formattedAddress: 'At the park',
  id: '',
  name: '',
};
const secondLocationAtCustomLocation = {
  locationType: LocationType.OWNER_CUSTOM,
  formattedAddress: 'At the Beach',
  id: '',
  name: '',
};
const firstLocationAtClientPlace = {
  locationType: LocationType.CUSTOM,
  formattedAddress: ``,
  id: ``,
  name: ``,
};
const firstStaff = {
  id: 'firstStaffId',
  name: 'Avraham',
};
const secondStaff = {
  id: 'secondStaffId',
  name: 'Itzhak',
};
const thirdStaff = {
  id: 'thirdStaffId',
  name: 'Jacob',
};
const multipleLocationsAtOwnerCustomLocation = [
  firstLocationAtCustomLocation,
  secondLocationAtCustomLocation,
];
const multipleLocationsAtBusinessLocation = [
  firstLocationAtBusinessLocation,
  secondLocationAtBusinessLocation,
  thirdLocationAtBusinessLocationWithoutFormattedAddress,
];
const multipleLocationsAtOwnerCustomAndBusinessLocations = [
  firstLocationAtBusinessLocation,
  firstLocationAtCustomLocation,
];
const multipleStaffMembers = [firstStaff, secondStaff, thirdStaff];

export function mockGetSlotsForSelectedDate(
  date: string,
): QueryAvailabilityResponse {
  let availabilityEntries: SlotAvailability[] = [];
  const day = date.substr(0, date.indexOf('T'));

  if (date === mockDateAvailability.dateWithSlots1) {
    const startTimes = [
      '06:00',
      '06:03',
      '06:06',
      '06:10',
      '06:13',
      '06:16',
      '06:20',
      '06:21',
      '06:25',
      '06:26',
      '06:27',
      '06:30',
      '06:45',
      '07:00',
      '07:01',
      '07:02',
      '07:03',
      '07:04',
      '07:05',
      '07:06',
      '07:07',
      '07:08',
      '07:09',
      '07:10',
      '07:15',
      '07:45',
      '08:00',
      '08:00',
      '08:00',
      '08:15',
      '08:15',
      '08:30',
      '08:30',
    ];
    const bookableSlotsAvailability = startTimes.map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          spotsOpen: 5,
          spotsTotal: 10,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['12:00', '14:00', '14:30'][index % 3],
            location: multipleLocationsAtBusinessLocation[index % 3],
            resource: multipleStaffMembers[index % 3],
          }),
        };
      },
    );

    const slotAvailabilityWithOwnerCustomLocations = ['11:00', '11:00'].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          spotsOpen: 5,
          spotsTotal: 10,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['12:00', '14:00'][index % 2],
            location: multipleLocationsAtOwnerCustomLocation[index % 2],
            resource: multipleStaffMembers[index % 3],
          }),
        };
      },
    );

    const slotAvailabilityWithClientPlaceLocation = ['13:00', '13:00'].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          spotsOpen: 5,
          spotsTotal: 10,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['14:00', '14:30'][index % 2],
            location: firstLocationAtClientPlace,
            resource: multipleStaffMembers[index % 3],
          }),
        };
      },
    );

    const slotAvailabilityWithOwnerBusinessWithAndWithoutIdLocations = [
      '15:00',
      '15:00',
    ].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          spotsOpen: 5,
          spotsTotal: 10,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['16:00', '16:30'][index % 2],
            location:
              multipleLocationsAtOwnerCustomAndBusinessLocations[index % 2],
            resource: multipleStaffMembers[index % 3],
          }),
        };
      },
    );

    const fullSlotAvailability: SlotAvailability = {
      isBookable: true,
      spotsOpen: 10,
      spotsTotal: 10,
      slot: createSlot({
        id: 'full',
        scheduleId: `123`,
        date: day,
        startTime: '17:00',
        endTime: '18:49',
        location: firstLocationAtBusinessLocation,
        resource: firstStaff,
      }),
    };

    const timeSlotWithOnlyMultiplyDurations = ['18:00', '18:00'].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          spotsOpen: 5,
          spotsTotal: 10,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['18:30', '18:45'][index % 2],
            location: firstLocationAtBusinessLocation,
            resource: firstStaff,
          }),
        };
      },
    );

    const timeSlotWithOnlyMultiplyLocations = [
      '19:00',
      '19:00',
      '19:00',
      '19:00',
      '19:00',
      '19:00',
      '19:00',
    ].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          spotsOpen: 5,
          spotsTotal: 10,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: '19:30',
            location: [
              ...multipleLocationsAtBusinessLocation,
              ...multipleLocationsAtOwnerCustomLocation,
              firstLocationAtClientPlace,
              firstLocationAtClientPlace,
            ][index % 7],
            resource: firstStaff,
          }),
        };
      },
    );

    const timeSlotWithOnlyMultiplyStaff = ['20:00', '20:00'].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          spotsOpen: 5,
          spotsTotal: 10,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: '19:30',
            location: firstLocationAtBusinessLocation,
            resource: multipleStaffMembers[index % 2],
          }),
        };
      },
    );

    const timeSlotWithStaffAtDifferentLocations = [
      '21:00',
      '21:00',
      '21:00',
    ].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          spotsOpen: 5,
          spotsTotal: 10,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: '21:30',
            location: [
              firstLocationAtBusinessLocation,
              secondLocationAtBusinessLocation,
              firstLocationAtBusinessLocation,
            ][index % 3],
            resource: [firstStaff, secondStaff, secondStaff][index % 3],
          }),
        };
      },
    );
    availabilityEntries = [
      ...bookableSlotsAvailability,
      ...slotAvailabilityWithOwnerCustomLocations,
      ...slotAvailabilityWithClientPlaceLocation,
      ...slotAvailabilityWithOwnerBusinessWithAndWithoutIdLocations,
      fullSlotAvailability,
      ...timeSlotWithOnlyMultiplyDurations,
      ...timeSlotWithOnlyMultiplyLocations,
      ...timeSlotWithOnlyMultiplyStaff,
      ...timeSlotWithStaffAtDifferentLocations,
    ];
  } else if (date === mockDateAvailability.dateWithSlots2) {
    const tooLateToBookSlotAvailability: SlotAvailability = {
      isBookable: false,
      slot: createSlot({
        id: 'tooLateToBook',
        scheduleId: `123`,
        date: day,
        startTime: '14:00',
        endTime: '15:30',
        location: firstLocationAtBusinessLocation,
        resource: firstStaff,
      }),
      spotsOpen: 4,
      spotsTotal: 10,
      bookingPolicyViolations: {
        isTooLateToBook: true,
      },
    };

    const slotAvailabilitiesWithOpenWaitingList = [
      '15:00',
      '15:00',
      '15:30',
    ].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          slot: createSlot({
            id: `open-waitlist-${index}`,
            scheduleId: `124`,
            date: day,
            startTime,
            endTime: ['16:00', '16:30'][index % 2],
            location:
              multipleLocationsAtOwnerCustomAndBusinessLocations[index % 2],
            resource: multipleStaffMembers[index % 3],
          }),
          spotsOpen: 10,
          spotsTotal: 10,
          waitingList: {
            spotsOpen: 4,
            spotsTotal: 10,
          },
        };
      },
    );
    const slotAvailabilitiesWithClosedWaitingList = [
      '15:30',
      '16:00',
      '17:00',
    ].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: false,
          slot: createSlot({
            id: `closed-waitlist-${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['16:00', '16:30'][index % 2],
            location:
              multipleLocationsAtOwnerCustomAndBusinessLocations[index % 2],
            resource: multipleStaffMembers[index % 3],
          }),
          spotsOpen: 10,
          spotsTotal: 10,
          waitingList: {
            spotsOpen: 0,
            spotsTotal: 10,
          },
        };
      },
    );

    const slotAvailabilitiesWithOptionsThatAreNotOnlyWaitingList = [
      '18:00',
      '18:00',
    ].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['18:49', '19:30'][index % 2],
            location: [
              firstLocationAtBusinessLocation,
              secondLocationAtBusinessLocation,
            ][index % 2],
            resource: firstStaff,
          }),
          spotsOpen: [6, 10][index % 2],
          spotsTotal: 10,
          waitingList: [
            { spotsOpen: 10, spotsTotal: 10 },
            {
              spotsOpen: 0,
              spotsTotal: 10,
            },
          ][index % 2],
        };
      },
    );

    availabilityEntries = [
      tooLateToBookSlotAvailability,
      ...slotAvailabilitiesWithOpenWaitingList,
      ...slotAvailabilitiesWithClosedWaitingList,
      ...slotAvailabilitiesWithOptionsThatAreNotOnlyWaitingList,
    ];
  } else if (date === mockDateAvailability.dateWithSlots3) {
    const bookableSlotsAvailability = ['09:00', '11:30'].map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: '18:49',
            location: firstLocationAtBusinessLocation,
            resource: firstStaff,
          }),
          spotsOpen: 5,
          spotsTotal: 10,
        };
      },
    );
    const tooEarlyToBookSlotAvailability: SlotAvailability = {
      isBookable: false,
      slot: createSlot({
        id: 'tooEarlyToBookSlot',
        scheduleId: `123`,
        date: day,
        startTime: '18:00',
        endTime: '18:49',
        location: firstLocationAtBusinessLocation,
        resource: firstStaff,
      }),
      spotsOpen: 4,
      spotsTotal: 10,
      bookingPolicyViolations: {
        isTooEarlyToBook: true,
      },
    };
    availabilityEntries = [
      ...bookableSlotsAvailability,
      tooEarlyToBookSlotAvailability,
    ];
  } else if (date === mockDateAvailability.dateWithSlots4) {
    const fullSlotAvailability: SlotAvailability = {
      isBookable: false,
      spotsOpen: 10,
      spotsTotal: 10,
      slot: createSlot({
        id: 'full',
        scheduleId: `123`,
        date: day,
        startTime: '17:00',
        endTime: '18:49',
        location: firstLocationAtBusinessLocation,
        resource: firstStaff,
      }),
    };
    availabilityEntries = [fullSlotAvailability];
  } else if (date === mockDateAvailability.dateWithRealSession) {
    // Date for real session is 2021-04-07
    const startTimes = ['12:00'];
    const bookableSlotsAvailability = startTimes.map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `7c53b7d0-7850-429f-be7f-a60dbe3f395f`,
            date: day,
            startTime,
            endTime: '13:00',
            location: {
              locationType: ServiceLocationType.OWNER_BUSINESS,
              formattedAddress: 'Los Angeles',
              id: ``,
              name: 'Los Angeles',
            },
            resource: {
              id: `76570209-101f-409b-af97-b445bdb63125`,
              name: 'David',
            },
          }),
          spotsOpen: 5,
          spotsTotal: 10,
        };
      },
    );
    availabilityEntries = [...bookableSlotsAvailability];
  }

  const queryAvailabilityResponse: QueryAvailabilityResponse = {
    availabilityEntries,
  };

  return queryAvailabilityResponse;
}

export const mockDateAvailability = {
  noSlotsDate: getLocalDateTimeStartOfDay(new Date()),
  dateWithSlots1: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 2),
  ),
  dateWithSlots2: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 4),
  ),
  dateWithSlots3: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 7),
  ),
  dateWithSlots4: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 10),
  ),
  dateWithNoSlots1: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 3),
  ),
  dateWithNoSlots2: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 6),
  ),
  dateWithNoSlots3: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 9),
  ),
  dateWithNoSlots4: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 12),
  ),
  dateWithRealSession: getLocalDateTimeStartOfDay(new Date(1617786000000)),
};

const createSlot = ({
  id,
  scheduleId,
  date,
  startTime,
  endTime,
  location,
  resource,
}: {
  id: string;
  scheduleId: string;
  date: string;
  startTime: string;
  endTime: string;
  location: any;
  resource: SlotResource;
}) => {
  return {
    id,
    scheduleId,
    start: `${date}T${startTime}:00.000Z`,
    end: `${date}T${endTime}:00.000Z`,
    location,
    resource,
  };
};
