import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';

export type SetSelectedTime = (selectedTime: string | undefined) => void;

export function createSetSelectedTimeAction({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>): SetSelectedTime {
  return async (selectedTime: string | undefined) => {
    const [state, setState] = getControllerState();
    const { availableSlots } = state;

    let bookableSlots;
    if (selectedTime) {
      bookableSlots = availableSlots?.availabilityEntries?.filter(
        (availableSlot) => {
          return (
            availableSlot.slot?.start === selectedTime &&
            availableSlot.isBookable
          );
        },
      );
    }

    setState({
      calendarErrors: [],
      selectedBookingPreferences: [],
      bookableSlotsAtSelectedTime: bookableSlots ?? [],
      selectedTime,
    });
  };
}
